
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
.customtable
{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px;
  font-family: 'Roboto', sans-serif;
}

.innerbox
{
  width: 80%;
}

.headingtable
{
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-evenly;
  background-color: rgb(240, 240, 240);
  height: 70px;
  margin-bottom: 40px;
  /* flex: 1; */
}

.custablecol
{
  flex: 1;
  width: 100%;
  align-items: center;
  justify-content: center;
  /* background-color: antiquewhite;
   */
   /* border: 1px solid; */
   display: flex;
   text-align: center;
   word-wrap:normal;
   /* width: 200px; */
   width: 100px;
   /* background-color: aliceblue; */
   /* border: 2px solid black; */
  
}


.cusrow
{
  width: 100%;
  display: flex;
  /* flex: 1; */
  display: flex;
  justify-content: space-evenly;
  margin-top: 20px;
  margin-bottom: 40px;
  border-bottom: 1.5px solid rgb(194, 194, 194);
}

.accno
{
  flex: 1;
  align-items: center;
  justify-content: center;
  display: flex;
}

.anchor{
  color: rgb(0, 63, 145);
}

.gre
{
  display: flex;
  flex-direction: column;
  flex: 5;
}

.grenested
{
  display: flex;
  justify-content: space-evenly;
  flex: 1;
}


.grenested
{
  margin-bottom: 20px;
 
  height: 100%;
  width: 100%;
}

.adres
{
  display: flex;
  align-items: center;
  justify-content: center;
  /* flex-direction: column; */
  flex: 1;
  /* background-color: aquamarine; */
  height: 100%;
  width: 100%;
}

.custablecol button{
  border: none;
  border-radius: 5px;
  padding: 10px 15px;margin: 5px;
  cursor: pointer;
}

.btn1
{
  background-color: rgb(154, 255, 154);
}

.btn2
{
  background-color: rgb(255, 154, 196);

}